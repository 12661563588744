// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.commonLi {
  float: left;
  padding: 0 (31rem / 100);
  height: (40rem / 100);
  line-height: (40rem / 100);
  font-size: (18rem / 100);
  text-align: center;
  margin-right: (33rem / 100);
  cursor: pointer;
}

.live-container {
  @extend %out-box;
  background: url('https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg');

  .live-content {
    @extend %inside-box;
    padding-top: (45rem / 100);
    padding-bottom: (160rem / 100);

    // 顶部
    .live-switch-type {
      display: flex;
      margin-top: 0.12rem;
      margin-bottom: (20rem / 100);

      &-title {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #761F1E;
        line-height: (32rem / 100);
        margin-right: (10rem / 100);
      }

      &-content {
        display: flex;
        flex-wrap: wrap;

        &-li {
          min-width: (100rem / 100);
          height: (32rem / 100);
          margin: 0 (30rem / 100) (12rem / 100) 0;
          background: url('https://image.bookgo.com.cn/webculture/jm/normal_type_li2.png') no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: (32rem / 100);
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          padding-left: 0.2rem;
          padding-right: 0.2rem;
          cursor: pointer;
        }
        .imgType {
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
          no-repeat;
          background-size: 100% 100%;
        }
        &-li-active {
          background: url('https://image.bookgo.com.cn/webculture/jm/activity_type_li.png') no-repeat !important;
          background-size: 100% 100% !important;
          color: #FFFFFF;
          font-weight: 500;
        }
      }
    }
  }
}
@mixin text-overflow($width: 100%) {
  width: $width;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
// 直播列表
.live-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-li {
    width: (380rem / 100);
    height: (368rem / 100);
    box-sizing: border-box;
    background: #F3EDE0 ;
    margin-right: (30rem / 100);
    margin-bottom: (30rem / 100);
    border: 4px solid #f3ede0;
    cursor: pointer;
    &-banner {
      width: (340rem / 100);
      height: (227rem / 100);
      margin: (20rem / 100) auto (7rem / 100);
      box-sizing: border-box;
      //padding: (20rem / 100);
      position: relative;
      //margin-bottom: (30rem / 100);

        .listState{
        position: absolute;
        left: 0rem;
        top: 0rem;
        display: flex;
        align-items: center;
        padding: 0.035rem 0.098rem 0.035rem 0.098rem;
        color: white;
        }
        .current1{
          .live-ul-li-banner-icon{
            width: (17.65rem / 100);
            height: (15rem / 100);
            // float: left;
            margin-right: (10rem / 100);
        
          }
          background: #761F1E;
        }
        .current2{
          background: #CD9E56;
        }
        .current3{
          background: #000000;
        }
        .listStateFont{
          font-size: 0.15rem;
          display: flex;
        align-items: center;
        //width: (98rem / 100);
        height: (34rem / 100);
        }

        //.numberState{
        //position: absolute;
        //right: 0rem;
        //top: 0rem;
        //display: flex;
        //align-items: center;
        //height:(15rem / 100);
        //padding: 0.035rem 0.098rem 0.035rem 0.098rem;
        //color: white;
        //.live-ul-li-banner-number-icon{
        //width: (17.65rem / 100);
        //height: (15rem / 100);
        // float: left;
        //margin-right: (10rem / 100);
        //}
        //.live-ul-li-banner-title{
        //  //height: (15rem / 100);
        //}
        //background: #C7C8C8;
        //}
        .numberAfterState1{
          position: absolute;
          left: 0rem;
          bottom: 0rem;
          .live-ul-li-banner-numberAfter-background{
            position: relative;
            margin-bottom: 0rem;
            margin-left: 0rem;
            //height: 25px;
            width:100%;
            overflow: hidden;
            opacity: 100;
          }
        //display: flex;
        //align-items: center;
        //width: 272px;
        //padding: 0.035rem 0.098rem 0.035rem 0.098rem;
        }
        .numberAfterState{
          position: absolute;
        left: 0rem;
        bottom: 0rem;
        display: flex;
        align-items: center;
        padding: 0.035rem 0.098rem 0.035rem 0.098rem;
        color: white;
        .live-ul-li-banner-numberAfter-icon{
          width: (22.86rem / 100);
        height: (22.86rem / 100);
        // float: left;
        margin-right: (10rem / 100);
        }
        
        opacity: 100;
        }
      
    }

    &-bottom {
      width: (340rem / 100);
      margin: 0 auto;

      &-title {
        width: 100%;
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }

      &-des {
        width: 100%;
        // height: (16rem / 100);
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;

        &-left{
          display: flex;
          align-items: center;
          .activity-li-bottom-icon {
            width: (24rem / 100);
            height: (24rem / 100);
            // float: left;
            //margin-right: (10rem / 100);
          }
  
          .activity-li-bottom-icon-title {
            // float: left;
            @include text-overflow(
              $width: (
                340rem / 100,
              )
            );
            margin-left: 10px;
            font-size: (16rem / 100);
            font-family: PingFangSC-Regular, PingFang SC;
            color: #273143;
            line-height: (16rem / 100);
          }
        }
        &-right
        {
          //display: flex;
          //align-items: center;
          //position: absolute;
          //font-size: 0.15rem;
          flex: 1;
          text-align: right;  
          //height:(22rem/100);
          
          .live-ul-li-bottom-des-right-title{
            
            line-height: (16rem / 100);
          }
        }
        
      }

      &-status {
        width: (100rem / 100);
        height: (26rem / 100);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: (14rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        text-align: center;
        line-height: (26rem / 100);
        margin-bottom: (10rem / 100);
      }

      .live-status-bg1 {
        background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_starting.png");
        color: #ffffff;
      }

      .live-status-bg2 {
        background-image: url("https://image.bookgo.com.cn/webculture/jm/detail/detail_not_start.png");
        color: #761f1e;
      }

      &-sponsor {
        margin-bottom: (12rem / 100);
      }

      &-sponsor,
      &-summary {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (16rem / 100);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &-li:nth-of-type(3n) {
    margin-right: 0;
  }

  &-li:hover {
    border: 4px solid #cd9e56;
  }
}